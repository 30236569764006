import { combineReducers } from 'redux';
import auth from './auth';
import home from './home';
import settings from './settings';
import footer from './footer';

const appReducer = combineReducers({
  auth,
  home,
  settings,
  footer
});

export default (state, action) => appReducer(state, action);
