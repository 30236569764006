import React from 'react';
import PropTypes from 'prop-types';

export default function FooterComponentLogo(props) {
  const { styles: divStyles, src, href, imageStyles } = props;

  return (
    <div style={{ height: '2.5rem', ...divStyles }}>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <img
            src={src}
            alt="logo"
            style={{ height: '100%', ...imageStyles }}
          />
        </a>
      ) : (
        <img src={src} alt="logo" style={{ height: '100%', ...imageStyles }} />
      )}
    </div>
  );
}

FooterComponentLogo.propTypes = {
  styles: PropTypes.object,
  imageStyles: PropTypes.object,
  href: PropTypes.string,
  src: PropTypes.string.isRequired
};

FooterComponentLogo.defaultProps = {
  styles: undefined,
  imageStyles: undefined,
  href: undefined
};
