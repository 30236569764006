import { connect } from 'react-redux';

import Home from '../../Components/Home';
import { getApps, orderApps } from '../../reducers/home';
import WithDefaults from '../WithDefaults';

export const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  ...state.home,
  ...ownProps
});

export const mapDispatchToProps = (dispatch) => ({
  async orderApps(...args) {
    return orderApps(dispatch, ...args);
  },
  async getApps(...args) {
    return getApps(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(Home));
