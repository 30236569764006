import config from '../Config';
import { CustomAxios } from './customAxios';

export class InternalAxios extends CustomAxios {
  constructor() {
    super(config.apiGateway.URL);
  }

  async getSettings() {
    return this.instance.get('/settings');
  }

  async updateSettings(body) {
    return this.instance.patch('/settings', body);
  }

  async sendEnquiry(body) {
    return this.instance.post('/send-enquiry', body);
  }

  async upload(body) {
    return this.instance.post('/upload', body);
  }

  async getPresignedUrl(body) {
    return this.instance.post('/generate-presigned-url', body);
  }

  async getUserByForwoodUUID() {
    return this.instance.get(`/user/${this.forwoodUUID}`).then((res) => {
      this.currentUser = res;
    });
  }

  async patchUsersAppsWeight(appsWeight) {
    return this.instance.patch('/user', {
      data: { id: this.forwoodUUID, type: 'user', attributes: { appsWeight } }
    });
  }
}

export const internalAxios = new InternalAxios();
