/* eslint-disable max-classes-per-file */

import axios from 'axios';
import { message } from 'antd';

const axiosNotifyMessage = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    Object.keys(error.response.data.errors).forEach((key) => {
      message.error(`${key}: ${error.response.data.errors[key]}`);
    });
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    message.error('No response from server');
  } else {
    // Something happened in setting up the request that triggered an Error
    message.error('Something went wrong, please contact developers');
  }
};

export class CustomAxios {
  constructor(baseURL) {
    /** @type {string} Base URL for the API */
    this.baseURL = baseURL;

    /** @type {function(): Promise<any>} Function to refresh authorization token */
    this.refreshUserTokens = null;

    /** @type {import('axios').AxiosInstance | null} */
    this.instance = null;

    this.forwoodUUID = null;

    this.currentUser = null;
  }

  createInstance(jwtToken) {
    this.instance = axios.create({
      baseURL: this.baseURL,
      headers: { Authorization: `Bearer ${jwtToken}` }
    });
  }

  responseInterceptor() {
    this.instance.interceptors.response.use(
      (response) => response.data.data,
      async (error) => {
        const originalRequest = error.config;

        // If the error is not 401
        if (error.response.status !== 401) {
          // we simply notify the error message
          axiosNotifyMessage(error);
          // and reject the promise so that the error can be handled by the caller
          return Promise.reject(error);
        }

        // If the error is 401
        if (this.refreshUserTokens) {
          try {
            // we refresh the authorization token
            const tokens = await this.refreshUserTokens();
            // and update the authorization header
            this.requestInterceptor(tokens.idToken);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
          // and retry the original request
          return this.instance(originalRequest);
        }

        return Promise.reject(error);
      }
    );
  }

  requestInterceptor(jwtToken) {
    this.instance.interceptors.request.use((config) => {
      config.headers.authorization = `Bearer ${jwtToken}`;
      return config;
    });
  }

  init(jwtToken) {
    this.createInstance(jwtToken);
    this.responseInterceptor();
  }
}
