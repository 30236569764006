import React from 'react';
import PropTypes from 'prop-types';
import FooterComponent from '../FooterComponent';
import FooterComponentLogo from '../FooterComponentLogo';
import resourceIcon from '../../images/resource.png';
import LinkedButton from '../LinkedButton';

export default function ForwoodResources(props) {
  const { t, isAdmin } = props;

  return (
    <FooterComponent
      title={t('forwoodResources:title')}
      description={t('forwoodResources:desc')}
      logo={<FooterComponentLogo src={resourceIcon} />}
      handlerComponent={(
        <LinkedButton
          href={window.location.toString().replace('id.', 'resources.')}
          title={t('forwoodResources:button')}
        />
      )}
      isAdmin={isAdmin}
    />
  );
}

ForwoodResources.propTypes = {
  t: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired
};
