import { connect } from 'react-redux';

import Footer from '../../Components/Footer';
import { getSettings } from '../../reducers/settings';
import WithDefaults from '../WithDefaults';

export const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
  footer: state.footer
});

export const mapDispatchToProps = (dispatch) => ({
  getSettings(...args) {
    return getSettings(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(Footer));
