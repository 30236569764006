import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row } from 'antd';

/**
 * @param {object} props - The component props.
 * @param {string} props.href
 * @param {string} props.title
 */
export default function LinkedButton(props) {
  const { href, title } = props;
  return (
    <Row style={{ marginTop: '1rem' }}>
      <Button>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </Button>
    </Row>
  );
}

LinkedButton.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
