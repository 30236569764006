import { internalAxios } from '../utils/internalAxios';

const FETCH_SETTINGS = 'FETCH_SETTINGS';
const PATCH_SETTINGS = 'PATCH_SETTINGS';

export function settingsDefaultState() {
  return {};
}

export async function getSettings(dispatch) {
  let settings = settingsDefaultState();

  await internalAxios
    .getSettings()
    .then((data) => {
      settings = data.attributes;
    })
    .catch(() => {});

  return dispatch({ type: FETCH_SETTINGS, payload: settings });
}

export async function updateSettings(dispatch, body) {
  let settings = settingsDefaultState();

  await internalAxios
    .updateSettings(body)
    .then((data) => {
      settings = data.attributes;
    })
    .catch(() => {});

  return dispatch({ type: PATCH_SETTINGS, payload: settings });
}

export default (state = settingsDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_SETTINGS:
      return payload;
    case PATCH_SETTINGS:
      return payload;
    default:
      return state;
  }
};
