import { connect } from 'react-redux';
import { updateSettings } from '../../reducers/settings';
import WithDefaults from '../WithDefaults';
import { setCustomFDDrawerVisible } from '../../reducers/footer';
import CustomForwoodDownload from '../../Components/CustomForwoodDownload';

export const mapStateToProps = (state) => ({
  settings: state.settings,
  visible: state.footer.customForwoodDownload.drawer.visible
});

export const mapDispatchToProps = (dispatch) => ({
  updateSettings(...args) {
    return updateSettings(dispatch, ...args);
  },
  setVisible(...args) {
    return setCustomFDDrawerVisible(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(CustomForwoodDownload));
