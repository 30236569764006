import { Card, Col, Icon } from 'antd';
import { getAppName } from 'componentlibrary';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export const AppIcon = (props) => {
  const { icon, name } = props;

  return (
    <Col className={styles.appIcon}>
      {!icon ? (
        <Icon type="api" className={styles.placeholder} />
      ) : (
        <img className={styles.icon} src={icon} alt={name} />
      )}
    </Col>
  );
};

export const ForwoodApp = (props) => {
  const { name, icon, isActive, t } = props;
  const appsName = getAppName(name, t);

  return (
    <Card bodyStyle={{ textAlign: 'center' }} className={!isActive ? styles.disabled : null}>
      <AppIcon name={name} icon={icon} />
      <div title={name} className={`app-name ${styles.appName}`}>
        {appsName}
      </div>
    </Card>
  );
};

AppIcon.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired
};

AppIcon.defaultProps = {
  icon: null
};

ForwoodApp.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

ForwoodApp.defaultProps = {
  icon: null
};
