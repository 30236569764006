import config from '../Config';
import { CustomAxios } from './customAxios';

export class IdAxios extends CustomAxios {
  constructor() {
    super(config.forwoodId.APIGATEWAY);
  }

  async getApps() {
    return this.instance.get('/apps');
  }
}

export const idAxios = new IdAxios();
