import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Row } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import FooterComponent from '../FooterComponent';
import FooterComponentLogo from '../FooterComponentLogo';
import LinkedButton from '../LinkedButton';
import config from '../../Config';

import googlePlayBadge from '../../images/google-play-badge.png';
import appStoreBadge from '../../images/app-store-badge.png';

import styles from './styles.module.scss';

import downloadIcon from '../../images/download.png';
import CustomForwoodDownload from '../../Containers/CustomForwoodDownload';

export function DownloadPopupContent(props) {
  const { showStoreButtons, showQRCode } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {showStoreButtons && (
        <div className={styles.container}>
          <FooterComponentLogo
            src={googlePlayBadge}
            href={config.reactApp.CVC_ANDROID}
            styles={{ marginBottom: '.5rem' }}
            imageStyles={{ width: '100%' }}
          />
          <FooterComponentLogo
            src={appStoreBadge}
            href={config.reactApp.CVC_IOS}
            imageStyles={{ width: '100%' }}
          />
        </div>
      )}
      {showQRCode && <QRCodeCanvas value={`${config.reactApp.WEB_URL}/#downloadRedirect`} />}
    </div>
  );
}

export function DownloadButton(props) {
  const { popoverVisible, setPopoverVisible, t, settings } = props;
  const { showStoreButtons, showQRCode } = settings.download;

  if (navigator.userAgent.match(/Android/i)) {
    return <LinkedButton href={config.reactApp.CVC_ANDROID} title={t('forwoodDownload:button')} />;
  }

  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return <LinkedButton href={config.reactApp.CVC_IOS} title={t('forwoodDownload:button')} />;
  }

  const translationVariant = config.reactApp.TRANSLATION_VARIANT || '';
  const popupContent = `<div class="ql-align-center">${t(`forwoodDownload:content${translationVariant}`)}</div>`;

  return (
    <Row style={{ marginTop: '1rem' }}>
      <Popover
        content={
          <DownloadPopupContent showStoreButtons={showStoreButtons} showQRCode={showQRCode} />
        }
        title={<div dangerouslySetInnerHTML={{ __html: popupContent }} />}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
      >
        <Button>{t('forwoodDownload:button')}</Button>
      </Popover>
    </Row>
  );
}

export default function ForwoodDownload(props) {
  const { t, settings, isAdmin } = props;
  const { forwoodDownload, setFDPopoverVisible, setCustomFDDrawerVisible } = props;

  const windowLocation = window.location.hash;
  const downloadLocation = window.location.hash === '#downloadPopover';
  const popoverVisible = downloadLocation || forwoodDownload.popover.visible;

  if (windowLocation) window.history.replaceState(null, null, ' ');

  return (
    <FooterComponent
      title={t('forwoodDownload:title')}
      description={t('forwoodDownload:desc')}
      logo={<FooterComponentLogo src={downloadIcon} />}
      handlerComponent={(
        <DownloadButton
          popoverVisible={popoverVisible}
          setPopoverVisible={setFDPopoverVisible}
          settings={settings}
          t={t}
        />
      )}
      customComponent={<CustomForwoodDownload title={t('contactSupport:title')} t={t} />}
      setCustomVisible={setCustomFDDrawerVisible}
      isAdmin={isAdmin}
    />
  );
}

DownloadPopupContent.propTypes = {
  showStoreButtons: PropTypes.bool.isRequired,
  showQRCode: PropTypes.bool.isRequired
};

DownloadButton.propTypes = {
  t: PropTypes.func.isRequired,
  popoverVisible: PropTypes.bool.isRequired,
  setPopoverVisible: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired
};

ForwoodDownload.propTypes = {
  t: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  forwoodDownload: PropTypes.object.isRequired,
  setFDPopoverVisible: PropTypes.func.isRequired,
  setCustomFDDrawerVisible: PropTypes.func.isRequired
};
