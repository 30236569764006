import { Button, Drawer, Form, Spin, Switch } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function CustomForwoodDownload(props) {
  const { visible, setVisible, title, t } = props;
  const { settings, updateSettings } = props;

  const { showStoreButtons, showQRCode } = settings.download;

  const [showStoreButtonsState, setShowStoreButtonsState] = useState(showStoreButtons);
  const [showQRCodeState, setShowQRCodeState] = useState(showQRCode);
  const [spinning, setSpinning] = useState(false);

  const handleOnClose = () => {
    setVisible(false);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setSpinning(true);

    const uSetting = {
      contactSupport: settings.contactSupport,
      download: {
        showStoreButtons: showStoreButtonsState,
        showQRCode: showQRCodeState,
        updatedAt: new Date().getTime()
      }
    };

    await updateSettings(uSetting).then(() => setVisible(false));

    setSpinning(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      visible={visible}
      onClose={handleOnClose}
      className="homepage-right-drawer"
    >
      <Spin spinning={spinning}>
        <Form onSubmit={handleOnSubmit}>
          <Form.Item
            label={t('forwoodDownload:custom:showStoreButtons')}
            required
            className="flex-form-item"
          >
            <Switch
              defaultChecked={showStoreButtonsState}
              onChange={() => setShowStoreButtonsState(!showStoreButtonsState)}
            />
          </Form.Item>
          <Form.Item
            label={t('forwoodDownload:custom:showQRCode')}
            required
            className="flex-form-item"
          >
            <Switch
              defaultChecked={showQRCodeState}
              onChange={() => setShowQRCodeState(!showQRCodeState)}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t('forwoodDownload:custom:submit')}
            </Button>
            <Button type="danger" style={{ marginLeft: '1rem' }} onClick={handleCancel}>
              {t('forwoodDownload:custom:cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

CustomForwoodDownload.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  updateSettings: PropTypes.func.isRequired
};
