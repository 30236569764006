import { Button, Drawer, Form, Spin, Switch } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function CustomContactSupport(props) {
  const { visible, setVisible, title, settings, updateSettings, t } = props;
  const { remindModal } = settings.contactSupport;

  const [showPopupState, setShowPopupState] = useState(remindModal.enable);
  const [modalOnOkState, setModalOnOkState] = useState(remindModal.openEnquiryFormOnOk !== false);
  const [spinning, setSpinning] = useState(false);

  const handleOnClose = () => {
    setVisible(false);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    setSpinning(true);

    const uSetting = {
      contactSupport: {
        remindModal: { enable: showPopupState, openEnquiryFormOnOk: modalOnOkState },
        updatedAt: new Date().getTime()
      },
      download: settings.download
    };

    await updateSettings(uSetting).then(() => setVisible(false));

    setSpinning(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      visible={visible}
      onClose={handleOnClose}
      className="homepage-right-drawer"
    >
      <Spin spinning={spinning}>
        <Form onSubmit={handleOnSubmit}>
          <Form.Item
            label={t('contactSupport:custom:showPopup')}
            required
            className="flex-form-item"
          >
            <Switch
              defaultChecked={showPopupState}
              onChange={() => setShowPopupState(!showPopupState)}
            />
          </Form.Item>
          <Form.Item
            label={t('contactSupport:custom:modalOnOk')}
            required
            className="flex-form-item"
          >
            <Switch
              defaultChecked={modalOnOkState}
              onChange={() => setModalOnOkState(!modalOnOkState)}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t('contactSupport:custom:submit')}
            </Button>
            <Button type="danger" style={{ marginLeft: '1rem' }} onClick={handleCancel}>
              {t('contactSupport:custom:cancel')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

CustomContactSupport.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired
};
