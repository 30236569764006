import { connect } from 'react-redux';
import config from '../../Config';

export const mapStateToProps = (state, ownProps) => ({
  jwtToken: state.auth.jwtToken,
  isAdmin: state.auth.cognitoGroups.includes(config.reactApp.UPDATE_SETTING_ACCESS_ROLE),
  includesForwoodIdAdmin: state.auth.cognitoGroups.includes('ForwoodIdAdmin'),
  ...ownProps
});

export default function (component) {
  return connect(mapStateToProps)(component);
}
