import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Base, Exception } from 'componentlibrary';
import isEmpty from 'lodash/isEmpty';
import { createBrowserHistory } from 'history';
import { routesWithPermission } from '../../routes';

const defaultHistory = createBrowserHistory();

export class App extends Base {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: null,
      mounted: false,
      isCurrentUserLoaded: false
    };
  }

  componentDidMount() {
    this.setAuth();
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    const { context, i18n, setDownloadPopoverVisible, setEnquiryModalVisible, setAuth } =
      this.props;
    const { refreshKey, isCurrentUserLoaded } = this.state;

    if (context.refreshKey !== refreshKey) {
      this.refresh(context.refreshKey);
    }
    if (
      i18n &&
      context.currentLanguage &&
      context.currentLanguage !== i18n.language
    ) {
      i18n.changeLanguage(context.currentLanguage);
    }

    if (!isEmpty(context.currentUser) && !isCurrentUserLoaded) {
      this.setState({ isCurrentUserLoaded: true }, () => {
        setAuth({ enableDragAndDrop: false, ...context, permissions: [] });
      });
    }

    if (![null, undefined].includes(context.downloadPopoverVisible)) {
      setDownloadPopoverVisible(context.downloadPopoverVisible);
    }
    if (![null, undefined].includes(context.enquiryModalVisible)) {
      setEnquiryModalVisible(context.enquiryModalVisible);
    }
  }

  get permissionDenied() {
    const { t } = this.props;

    return <Exception status={403} message={t('errors:403')} />;
  }

  setAuth() {
    const { context, setAuth } = this.props;

    // TODO: map context.cognitoGroups to permissions
    const permissions = [];

    setAuth({ enableDragAndDrop: false, ...context, permissions });
  }

  refresh(refreshKey) {
    const { history } = this.props;
    history.push('/');

    this.setState({ refreshKey });
  }

  render() {
    const { context, history } = this.props;
    const { mounted } = this.state;

    return (
      <Router history={history || defaultHistory}>
        {mounted &&
          renderRoutes(
            routesWithPermission(context.permission, this.permissionDenied)
          )}
      </Router>
    );
  }
}

App.propTypes = {
  t: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  setAuth: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(App);
