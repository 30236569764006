import { connect } from 'react-redux';
import WithDefaults from '../WithDefaults';
import { ContactSupport } from '../../Components/ContactSupport';
import {
  setCSDrawerVisible,
  setCSModalVisible,
  setCustomCSDrawerVisible
} from '../../reducers/footer';

export const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
  contactSupport: state.footer.contactSupport
});

export const mapDispatchToProps = (dispatch) => ({
  setCSDrawerVisible(...args) {
    return setCSDrawerVisible(dispatch, ...args);
  },
  setCSModalVisible(...args) {
    return setCSModalVisible(dispatch, ...args);
  },
  setCustomCSDrawerVisible(...args) {
    return setCustomCSDrawerVisible(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(ContactSupport));
