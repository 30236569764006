import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row, Spin } from 'antd';
import FooterComponent from '../FooterComponent';
import FooterComponentLogo from '../FooterComponentLogo';
import supportIcon from '../../images/support.png';
import ContactDrawer from '../ContactDrawer';
import CustomContactSupport from '../../Containers/CustomContactSupport';
import config from '../../Config';

export const ContactButton = (props) => {
  const { setCSModalVisible, t, setCSDrawerVisible } = props;
  const { openEnquiryFormOnOk, modalEnable } = props;

  return (
    <Row style={{ marginTop: '1rem' }}>
      <Button
        onClick={() => {
          if (modalEnable) {
            setCSModalVisible(true);
          } else {
            setCSDrawerVisible(openEnquiryFormOnOk);
          }
        }}
      >
        {t('contactSupport:button')}
      </Button>
    </Row>
  );
};

export const ContactSupport = (props) => {
  const { auth, t, settings, contactSupport, isAdmin } = props;
  const { setCSDrawerVisible, setCSModalVisible, setCustomCSDrawerVisible } = props;

  let modalVisible = contactSupport.modal.visible;

  const helpLocation = window.location.hash === '#help';
  if (helpLocation) {
    modalVisible = true;
    if (!settings.contactSupport.remindModal.enable) contactSupport.drawer.visible = true;
  }

  if (helpLocation) window.history.replaceState(null, null, ' ');

  const openEnquiryFormOnOk = settings.contactSupport.remindModal.openEnquiryFormOnOk !== false;

  const translationVariant = config.reactApp.TRANSLATION_VARIANT || '';
  const remindModalContent = t(`contactSupport:modal:content${translationVariant}`);

  return (
    <Spin spinning={!settings.contactSupport}>
      {settings.contactSupport.remindModal.enable && (
        <Modal
          visible={modalVisible}
          onOk={() => {
            setCSDrawerVisible(openEnquiryFormOnOk);
          }}
          cancelText={t('contactSupport:custom:cancel')}
          onCancel={() => setCSModalVisible(false)}
          cancelButtonProps={{ hidden: !openEnquiryFormOnOk }}
        >
          <div dangerouslySetInnerHTML={{ __html: remindModalContent }} />
        </Modal>
      )}
      <FooterComponent
        title={t('contactSupport:title')}
        description={t('contactSupport:desc')}
        logo={<FooterComponentLogo src={supportIcon} />}
        handlerComponent={(
          <ContactButton
            modalEnable={settings.contactSupport.remindModal.enable}
            openEnquiryFormOnOk={openEnquiryFormOnOk}
            setCSDrawerVisible={setCSDrawerVisible}
            setCSModalVisible={setCSModalVisible}
            t={t}
          />
        )}
        customComponent={<CustomContactSupport title={t('contactSupport:title')} t={t} />}
        setCustomVisible={setCustomCSDrawerVisible}
        isAdmin={isAdmin}
      />
      <ContactDrawer
        open={contactSupport.drawer.visible}
        setOpen={setCSDrawerVisible}
        auth={auth}
        t={t}
      />
    </Spin>
  );
};

ContactButton.propTypes = {
  t: PropTypes.func.isRequired,
  setCSModalVisible: PropTypes.func.isRequired,
  modalEnable: PropTypes.bool.isRequired,
  setCSDrawerVisible: PropTypes.func.isRequired,
  openEnquiryFormOnOk: PropTypes.bool.isRequired
};

ContactSupport.propTypes = {
  auth: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  contactSupport: PropTypes.object.isRequired,
  setCSDrawerVisible: PropTypes.func.isRequired,
  setCSModalVisible: PropTypes.func.isRequired,
  setCustomCSDrawerVisible: PropTypes.func.isRequired
};
