export const SET__CS_MODAL_VISIBLE = 'SET__CS_MODAL_VISIBLE';
export const SET__CS_DRAWER_VISIBLE = 'SET__CS_DRAWER_VISIBLE';
export const SET__FD_POPOVER_VISIBLE = 'SET__FD_POPOVER_VISIBLE';
export const SET__CUSTOM_CS_DRAWER_VISIBLE = 'SET__CUSTOM_CS_DRAWER_VISIBLE';
export const SET__CUSTOM_FD_DRAWER_VISIBLE = 'SET__CUSTOM_FD_DRAWER_VISIBLE';

export function footerDefaultState() {
  return {
    contactSupport: {
      modal: {
        visible: false
      },
      drawer: {
        visible: false
      }
    },
    customContactSupport: {
      drawer: {
        visible: false
      }
    },
    forwoodDownload: {
      popover: {
        visible: false
      }
    },
    customForwoodDownload: {
      drawer: {
        visible: false
      }
    }
  };
}

export function setCSModalVisible(dispatch, visible) {
  return dispatch({
    type: SET__CS_MODAL_VISIBLE,
    payload: visible
  });
}

export function setCSDrawerVisible(dispatch, visible) {
  return dispatch({
    type: SET__CS_DRAWER_VISIBLE,
    payload: visible
  });
}

export function setCustomCSDrawerVisible(dispatch, visible) {
  return dispatch({
    type: SET__CUSTOM_CS_DRAWER_VISIBLE,
    payload: visible
  });
}

export function setFDPopoverVisible(dispatch, visible) {
  return dispatch({
    type: SET__FD_POPOVER_VISIBLE,
    payload: visible
  });
}

export function setCustomFDDrawerVisible(dispatch, visible) {
  return dispatch({
    type: SET__CUSTOM_FD_DRAWER_VISIBLE,
    payload: visible
  });
}

const invisibleAll = (state) => {
  state.contactSupport.modal.visible = false;
  state.contactSupport.drawer.visible = false;
  state.customContactSupport.drawer.visible = false;
  state.forwoodDownload.popover.visible = false;
  state.customForwoodDownload.drawer.visible = false;
};

export default (state = footerDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case SET__CS_MODAL_VISIBLE:
      invisibleAll(state);
      return { ...state, contactSupport: { ...state.contactSupport, modal: { visible: payload } } };
    case SET__CS_DRAWER_VISIBLE:
      invisibleAll(state);
      return {
        ...state,
        contactSupport: { ...state.contactSupport, drawer: { visible: payload } }
      };
    case SET__CUSTOM_CS_DRAWER_VISIBLE:
      invisibleAll(state);
      return {
        ...state,
        customContactSupport: { ...state.customContactSupport, drawer: { visible: payload } }
      };
    case SET__FD_POPOVER_VISIBLE:
      invisibleAll(state);
      return {
        ...state,
        forwoodDownload: { ...state.forwoodDownload, popover: { visible: payload } }
      };
    case SET__CUSTOM_FD_DRAWER_VISIBLE:
      invisibleAll(state);
      return {
        ...state,
        customForwoodDownload: { ...state.customForwoodDownload, drawer: { visible: payload } }
      };
    default:
      return state;
  }
};
