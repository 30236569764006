import { connect } from 'react-redux';
import WithDefaults from '../WithDefaults';
import { setCustomFDDrawerVisible, setFDPopoverVisible } from '../../reducers/footer';
import ForwoodDownload from '../../Components/ForwoodDownload';

export const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
  forwoodDownload: state.footer.forwoodDownload
});

export const mapDispatchToProps = (dispatch) => ({
  setFDPopoverVisible(...args) {
    return setFDPopoverVisible(dispatch, ...args);
  },
  setCustomFDDrawerVisible(...args) {
    return setCustomFDDrawerVisible(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(ForwoodDownload));
