export function getLocalTimezone() {
  const timezone = new Date()
    .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' })
    .substring(4);

  const now = new Date();
  let offset = now.getTimezoneOffset() / 60;
  const sign = offset > 0 ? '-' : '+';
  offset = Math.abs(offset);
  const hours = `${(`0${now.getHours()}`).slice(-2)}`;
  const minutes = `${(`0${now.getMinutes()}`).slice(-2)}`;
  const formattedTime = `${hours}:${minutes}`;

  return `${timezone} (UTC${sign}${offset}: ${formattedTime})`;
}
