import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import styles from './styles.module.scss';

/**
 * @param {object} props - The component props.
 * @param {string} props.title
 * @param {string} props.description
 * @param {JSX.Element} props.logo
 * @param {JSX.Element} props.handlerComponent
 * @returns
 */
export default function FooterComponent(props) {
  const { title, description, logo, handlerComponent, isAdmin } = props;
  const { setCustomVisible, customComponent } = props;

  return (
    <div className={styles.footerComponent}>
      {isAdmin && (
        <Button icon="edit" className={styles.editButton} onClick={() => setCustomVisible(true)} />
      )}
      {customComponent}
      <h3>{title}</h3>
      <p>{description}</p>
      <div className={styles.bottom}>
        {logo}
        {handlerComponent}
      </div>
    </div>
  );
}

FooterComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  handlerComponent: PropTypes.object.isRequired,
  setCustomVisible: PropTypes.func.isRequired,
  customComponent: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired
};
