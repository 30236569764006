import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Upload } from 'antd';
import axios from 'axios';
import { getFileExtension } from '../../utils/getFileExtension';
import styles from './styles.module.scss';
import { internalAxios } from '../../utils/internalAxios';

const toMB = (bytes) => bytes / 1024 / 1024;
const filesToSize = (files) => toMB(files.reduce((p, c) => p + c.size, 0)).toFixed(2);

const uploadToS3 = async (url, file, onSuccess) => {
  axios.put(url, file, { headers: { 'Content-Type': file.type } }).then(() => onSuccess(file));
};

export default function S3Upload(props) {
  const { setFileList, fileList, t, maxUploadMb } = props;
  const [uploadError, setUploadError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleUploadRemove = (file) => {
    setFileList((prevList) => prevList.filter((f) => f.uid !== file.uid));
  };

  const beforeUpload = (file) => new Promise((resolve, reject) => {
    if (filesToSize([...fileList, file]) > maxUploadMb) {
      const error = t('contactSupport:drawer:uploadExceedError', { limit: `${maxUploadMb}MB` });
      setUploadError(error);
      return reject(new Error(`${error}`));
    }
    return resolve();
  });

  const onError = (file) => {
    const error = t('contactSupport:drawer:uploadServerError');
    setUploadError(error);
    setFileList(fileList.filter((f) => f.uid !== file.uid));
    setDisabled(false);
  };

  const onSuccess = (file) => {
    file.status = 'success';
    const newFile = new File([file], `${file.name} (${filesToSize([file])}MB)`, {
      type: file.type
    });
    newFile.uid = file.uid;
    newFile.extension = getFileExtension(file.name);
    setFileList([...fileList, newFile]);
    setDisabled(false);
  };

  const customRequest = async ({ file }) => {
    setDisabled(true);
    setUploadError('');
    file.status = 'uploading';
    setFileList([...fileList, file]);

    const extension = getFileExtension(file.name);

    await internalAxios
      .getPresignedUrl({
        uid: `${file.uid}.${extension}`,
        content_type: file.type
      })
      .then((res) => uploadToS3(res.attributes.upload_url, file, onSuccess))
      .catch(() => onError(file));
  };

  return (
    <div>
      <ul>
        <li>{`${t('contactSupport:drawer:fileType')}: ${t('contactSupport:drawer:allTypes')}`}</li>
        <li>{`${t('contactSupport:drawer:uploadMaximum')}: ${maxUploadMb}MB`}</li>
        <li>{`${t('contactSupport:drawer:uploaded')}: ${filesToSize(fileList)}MB`}</li>
      </ul>
      <Upload
        customRequest={customRequest}
        onRemove={handleUploadRemove}
        fileList={fileList}
        beforeUpload={beforeUpload}
      >
        <div>
          <Button disabled={disabled}>
            <Icon type="upload" />
            {t('contactSupport:drawer:uploadButton')}
          </Button>
          <span className={styles.uploadError}>{uploadError}</span>
        </div>
      </Upload>
    </div>
  );
}

S3Upload.defaultProps = {
  maxUploadMb: 7
};

S3Upload.propTypes = {
  setFileList: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  maxUploadMb: PropTypes.number
};
