import { idAxios } from '../utils/idAxios';
import { internalAxios } from '../utils/internalAxios';

const FETCH_ACTION = 'FETCH_ACTION';

export function homeDefaultState() {
  return {
    fetched: false,
    loading: true,
    apps: []
  };
}

const excludeResource = (apps) => apps.filter((app) => app.attributes.name !== 'Resources');

const flattenAttribute = (apps) => apps.map((app) => ({ ...app, ...app.attributes }));

export async function getApps(dispatch) {
  let apps = [];

  await idAxios
    .getApps()
    .then((data) => {
      apps = excludeResource(data);
      apps = flattenAttribute(apps);
    })
    .catch(() => {});

  return dispatch({ type: FETCH_ACTION, payload: apps });
}

export async function orderApps(dispatch, reorderedApps, origApps) {
  const appsWeight = {};
  reorderedApps.forEach((app, idx) => {
    appsWeight[app.name] = reorderedApps.length - idx;
  });

  let apps = reorderedApps;

  await new Promise((resolve) => {
    internalAxios.patchUsersAppsWeight(appsWeight).catch(() => {
      apps = origApps;
    });

    // add delay
    setTimeout(() => {
      resolve();
    }, 1000);
  });

  dispatch({ type: FETCH_ACTION, payload: apps });

  return { type: FETCH_ACTION, payload: apps };
}

export default (state = homeDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case FETCH_ACTION:
      return { apps: payload, loading: false, fetched: true };
    default:
      return state;
  }
};
