import { connect } from 'react-redux';

import App from '../../Components/App';
import { setAuth } from '../../reducers/auth';
import { setCSModalVisible, setFDPopoverVisible } from '../../reducers/footer';

export const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  ...ownProps
});

export const mapDispatchToProps = (dispatch) => ({
  setAuth(...args) {
    return setAuth(dispatch, ...args);
  },
  setDownloadPopoverVisible(...args) {
    return setFDPopoverVisible(dispatch, ...args);
  },
  setEnquiryModalVisible(...args) {
    return setCSModalVisible(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
