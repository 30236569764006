import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';

import rootReducer from '../reducers';
import { authDefaultState } from '../reducers/auth';
import { homeDefaultState } from '../reducers/home';
import { settingsDefaultState } from '../reducers/settings';
import { footerDefaultState } from '../reducers/footer';

export const initialStore = {
  auth: authDefaultState(),
  home: homeDefaultState(),
  settings: settingsDefaultState(),
  footer: footerDefaultState()
};

const middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export default createStore(rootReducer, initialStore, applyMiddleware(...middleware));
