import { idAxios } from '../utils/idAxios';
import { internalAxios } from '../utils/internalAxios';
import { parseJwt } from '../utils/jwt';

export const AUTH_SET_ACTION = 'AUTH_SET';

export function authDefaultState() {
  return {
    jwtToken: null,
    permission: null,
    user: null,
    currentLanguage: null,
    refreshTokens: null,
    refreshUserTokens: null
  };
}

/**
 * @param {Function} dispatch
 * @param {Object} payload
 */
export function setAuth(dispatch, payload) {
  internalAxios.refreshUserTokens = payload.refreshUserTokens;
  internalAxios.init(payload.jwtToken);

  idAxios.refreshUserTokens = payload.refreshUserTokens;
  idAxios.init(payload.jwtToken);

  const parsedJwt = parseJwt(payload.jwtToken);
  payload.cognitoGroups = parsedJwt ? parsedJwt['cognito:groups'] : [];

  internalAxios.forwoodUUID = parsedJwt ? parsedJwt['custom:forwood_uuid'] : null;
  idAxios.forwoodUUID = parsedJwt ? parsedJwt['custom:forwood_uuid'] : null;

  return dispatch({
    type: AUTH_SET_ACTION,
    payload
  });
}

export default (state = authDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case AUTH_SET_ACTION:
      return { ...state, ...payload };

    default:
      return state;
  }
};
