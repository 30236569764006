import React, { Component } from 'react';
import { Button, Divider, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import styles from './styles.module.scss';
import Footer from '../../Containers/Footer';
import { AppsDirectory } from '../AppsDirectory';

export class Home extends Component {
  constructor(props) {
    super(props);

    this.initialApps = 10;
  }

  componentDidMount() {
    const { fetched, getApps } = this.props;

    if (!fetched) getApps();
  }

  render() {
    const { t: trans, auth, loading, apps, orderApps, includesForwoodIdAdmin } = this.props;
    const { enableDragAndDrop } = auth;

    const displayName = `${auth.currentUser.preferredName || auth.currentUser.firstName} ${auth.currentUser.lastName}`;

    return (
      <Spin spinning={loading} size="large">
        <div className={styles.container}>
          <div>
            {(isEmpty(auth.currentUser) || !auth.currentUser) ? '' : trans('home:welcome', { name: displayName })}
            <Divider />
            {includesForwoodIdAdmin && (
              <Row>
                <Button type="primary" style={{ marginBottom: '20px' }} href="/apps-directory">
                  {trans('home:manageApps')}
                </Button>
              </Row>
            )}
            <AppsDirectory
              enableDragAndDrop={enableDragAndDrop}
              apps={apps}
              t={trans}
              orderApps={orderApps}
            />
          </div>
          {apps.length > 0 && <Footer t={trans} />}
        </div>
      </Spin>
    );
  }
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  apps: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  orderApps: PropTypes.func.isRequired,
  getApps: PropTypes.func.isRequired,
  includesForwoodIdAdmin: PropTypes.bool.isRequired
};

export default withTranslation(['home'])(Home);
