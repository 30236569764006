import React, { useState } from 'react';
import { Row, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ForwoodApp } from '../ForwoodApp';

import styles from './styles.module.scss';

export const Apps = (props) => {
  const { apps, numRendered, showedAll, orderApps, enableDragAndDrop, t } = props;

  const [items, setItems] = useState(apps);
  const [previousItems, setPreviousItems] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [spinning, setSpinning] = useState(false);

  const onOrderChange = async () => {
    const oldIds = previousItems.map((app) => app.id);
    const newIds = items.map((app) => app.id);
    const isEqual = _.isEqual(oldIds, newIds);
    if (isEqual) return;

    setSpinning(true);
    const updated = await orderApps(items, previousItems);
    setItems(updated.payload);
    setSpinning(false);
  };

  const handleDragStart = (index) => {
    setPreviousItems(items);
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null) return;

    if (index !== draggedIndex) {
      const updatedItems = [...items];
      const draggedItem = updatedItems[draggedIndex];
      updatedItems.splice(draggedIndex, 1);
      updatedItems.splice(index, 0, draggedItem);

      setItems(updatedItems);
      setDraggedIndex(index);
    }
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
    onOrderChange();
  };

  return (
    <Spin spinning={spinning}>
      <div className={styles.gridContainer}>
        {items.map((item, index) => {
          const { url, isActive, id } = item;
          return (
            <div
              key={id}
              draggable={enableDragAndDrop}
              onDragStart={() => handleDragStart(index)}
              onDragOver={() => handleDragOver(index)}
              onDragEnd={handleDragEnd}
              data-index={index}
              className={index === draggedIndex ? styles.dragged : ''}
              hidden={showedAll ? false : index >= numRendered}
              onClick={() => {
                if (isActive) window.open(url);
              }}
            >
              <ForwoodApp t={t} {...item} />
            </div>
          );
        })}
      </div>
    </Spin>
  );
};

export const AppsDirectory = (props) => {
  const { apps, t, orderApps, enableDragAndDrop } = props;
  const numInitial = 10;

  const [numRendered, setNumRendered] = useState(numInitial);

  const showedAll = numRendered === apps.length;

  const showHandler = () => {
    if (showedAll) setNumRendered(numInitial);
    if (!showedAll) setNumRendered(apps.length);
  };

  return (
    <Row>
      {apps.length > 0 && (
        <Apps
          apps={apps}
          numRendered={numRendered}
          showedAll={showedAll}
          orderApps={orderApps}
          enableDragAndDrop={enableDragAndDrop}
          t={t}
        />
      )}
      {apps.length > numInitial && (
        <Button type="link" block onClick={showHandler} className={styles.showAppsBtn}>
          {showedAll ? t('appDirectory:back') : t('appDirectory:allApps')}
        </Button>
      )}
    </Row>
  );
};

Apps.propTypes = {
  apps: PropTypes.array.isRequired,
  numRendered: PropTypes.number.isRequired,
  showedAll: PropTypes.bool.isRequired,
  orderApps: PropTypes.func.isRequired,
  enableDragAndDrop: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

AppsDirectory.propTypes = {
  apps: PropTypes.array.isRequired,
  orderApps: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  enableDragAndDrop: PropTypes.bool.isRequired
};
