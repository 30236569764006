import { connect } from 'react-redux';
import { updateSettings } from '../../reducers/settings';
import CustomContactSupport from '../../Components/CustomContactSupport';
import WithDefaults from '../WithDefaults';
import { setCustomCSDrawerVisible } from '../../reducers/footer';

export const mapStateToProps = (state) => ({
  settings: state.settings,
  visible: state.footer.customContactSupport.drawer.visible
});

export const mapDispatchToProps = (dispatch) => ({
  updateSettings(...args) {
    return updateSettings(dispatch, ...args);
  },
  setVisible(...args) {
    return setCustomCSDrawerVisible(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(CustomContactSupport));
